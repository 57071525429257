import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import PageContainer from '@system/page-container'
import React from 'react'
import SbEditable from 'storyblok-react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const useStyles = makeStyles((theme) => ({
  heroCarouselSwiper: {
    '--swiper-pagination-color': theme.palette.common.white,
    '--swiper-pagination-bullet-inactive-color': theme.palette.background.slate,
    '--swiper-pagination-bullet-inactive-opacity': '1',
    '--swiper-pagination-bullet-size': '10px',
    '--swiper-pagination-bullet-horizontal-gap': '6px',
  },
}))

const HeroCarousel = ({ blok }) => {
  const { frames, isPageWidth, timePerFrame } = blok
  const classes = useStyles()

  return (
    <SbEditable content={blok}>
      <PageContainer parentWidth={!isPageWidth}>
        <Swiper
          autoplay={{
            delay: timePerFrame ? timePerFrame * 1000 : 10000,
            disableOnInteraction: false,
          }}
          className={classes.heroCarouselSwiper}
          effect="fade"
          modules={[Autoplay, EffectFade, Pagination]}
          pagination={{
            clickable: true,
          }}
        >
          {frames.map((blok) => {
            return <SwiperSlide key={blok._uid}>{renderBlok(blok)}</SwiperSlide>
          })}
        </Swiper>
      </PageContainer>
    </SbEditable>
  )
}

export default HeroCarousel
